import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { Image, Text } from 'components/dataDisplay'
import { AspectContainer, WidthContainer } from 'components/layout'

import messages from './messages'

import bgImageDesktop from './images/bg-0-desktop.jpg?src-loader'
import bgImageMobile from './images/bg-0-mobile.jpg?src-loader'
import BrandsRow from '../BrandsRow/BrandsRow'


type ImageObject = {
  src: string
  aspect: number
}

type BrandsQualityBlockProps = {
  className?: string
  button?: React.ReactNode
  title?: Intl.Message | string
  subtitle?: Intl.Message | string
  text?: Intl.Message | string
  mobileImage?: ImageObject
  desktopImage?: ImageObject
}

const BrandsQualityBlock: React.FunctionComponent<BrandsQualityBlockProps> = (props) => {
  const { className, button = null, subtitle, title, text, mobileImage: customMobileImage, desktopImage: customDesktopImage } = props
  const { isMobile } = useDevice()

  const mobileImage = customMobileImage || bgImageMobile
  const desktopImage = customDesktopImage || bgImageDesktop

  if (isMobile) {
    return (
      <div className={cx(className, 'bg-light-beige')} data-testid="brandsSection">
        <AspectContainer aspect={mobileImage.aspect}>
          <Image
            src={mobileImage.src}
            sizes="100vw"
            fill
            alt="Bottles"
          />
        </AspectContainer>
        <div className="px-16 pt-24 text-center">
          {
            subtitle && (
              <Text className="mb-8" message={subtitle} style="eye2" color="gold-70" />
            )
          }
          <Text message={title || messages.title} style="h4" />
          <Text className="mt-24" message={text || messages.text} style="p3" />
          <BrandsRow />
          {button}
        </div>
      </div>
    )
  }

  return (
    <WidthContainer className={className} data-testid="brandsSection">
      <div className="bg-light-beige flex items-center justify-between">
        <div className="max-w-464 py-32 pl-64 pr-32">
          {
            subtitle && (
              <Text className="mb-8" message={subtitle} style="eye2" color="gold-70" />
            )
          }
          <Text message={title || messages.title} style="h3" />
          <Text className="mt-24" message={text || messages.text} style="p3" />
          {button}
        </div>
        <AspectContainer className="w-6/12" aspect={desktopImage.aspect}>
          <Image
            src={desktopImage.src}
            alt="Bottles"
            fill
            sizes="568px"
          />
        </AspectContainer>
      </div>
      <BrandsRow />
    </WidthContainer>
  )
}


export default React.memo(BrandsQualityBlock)
